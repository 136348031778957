import { addPropertyControls, ControlType, RenderTarget } from "framer"
import { motion, animate, useMotionValue, useTransform } from "framer-motion"
import { useCallback, useEffect } from "react"

function LayoutItem({
    children,
    angle,
    distance,
    unit,
    orientation,
    rotationValue,
    pointerEvents,
}) {
    const transformBase = `rotate(${
        orientation == "up" ? -angle : orientation == "in" ? 90 : -90
    }deg)`

    let transform = useTransform(
        rotationValue,
        (value) => `${transformBase} rotate(${-value}deg)`
    )

    return (
        <div
            style={{
                position: "absolute",
                inset: 0,
                transform: `rotate(${angle}deg) translate(${distance}${unit})`,
                pointerEvents: "none",
            }}
        >
            <motion.div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    translate: "-50% -50%",
                    transform: orientation == "up" ? transform : transformBase,
                    pointerEvents: pointerEvents ?? "auto",
                }}
            >
                {children}
            </motion.div>
        </div>
    )
}

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function CircularLayout(props) {
    const isCanvas = RenderTarget.current() === RenderTarget.canvas
    const { animation } = props
    const angle = props.randomAngle ? 0 : props.angle
    const rotation = useMotionValue(
        !isCanvas && props.randomize ? Math.random() * 360 : 0
    )

    useEffect(() => {
        if (!isCanvas && animation) {
            animate(
                rotation,
                rotation.get() + (animation.direction === "cw" ? 360 : -360),
                {
                    type: "tween",
                    duration: animation.duration,
                    ease: "linear",
                    repeat: Infinity,
                }
            )
        }
    }, [animation])

    const orientation = props.orientation
    const layers = props.layers
    const count = layers.length

    let layout = []
    for (let i = 0; i < layers.length; i++) {
        layout.push(
            <div key={i} style={{ display: "contents" }}>
                <LayoutItem
                    angle={(360 / count) * i + angle}
                    distance={props.distance}
                    unit={props.unit}
                    orientation={props.orientation}
                    rotationValue={rotation}
                    pointerEvents={props.style?.pointerEvents}
                >
                    {layers[i]}
                </LayoutItem>
            </div>
        )
    }

    return (
        <motion.div
            style={{
                position: "relative",
                overflow: props.overflow,
                rotate: useTransform(rotation, (value) => `${value}deg`),
                ...props.style,
            }}
        >
            {layout}
        </motion.div>
    )
}

CircularLayout.displayName = "Circular Layout"

addPropertyControls(CircularLayout, {
    layers: {
        type: ControlType.Array,
        control: {
            type: ControlType.ComponentInstance,
        },
    },
    orientation: {
        type: ControlType.Enum,
        defaultValue: "up",
        options: ["up", "in", "out"],
        optionTitles: ["Up", "In", "Out"],
        displaySegmentedControl: true,
    },
    randomAngle: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    angle: {
        type: ControlType.Number,
        defaultValue: 0,
        min: -360,
        max: 360,
        unit: "°",
        hidden(props) {
            return props.randomAngle
        },
    },
    distance: {
        type: ControlType.Number,
        defaultValue: 50,
        min: 0,
    },
    unit: {
        type: ControlType.Enum,
        defaultValue: "px",
        options: ["px", "%", "vw", "vh"],
        displaySegmentedControl: true,
    },
    animation: {
        type: ControlType.Object,
        optional: true,
        icon: "effect",
        controls: {
            duration: {
                type: ControlType.Number,
                defaultValue: 10,
                min: 0.1,
                step: 0.1,
                displayStepper: true,
                description: "Seconds to rotate 360°",
            },
            direction: {
                type: ControlType.Enum,
                defaultValue: "cw",
                options: ["cw", "ccw"],
                optionTitles: ["Clockwise", "Counterclockwise"],
                displaySegmentedControl: true,
                segmentedControlDirection: "vertical",
            },
        },
    },
    overflow: {
        type: ControlType.Enum,
        defaultValue: "visible",
        options: ["visible", "hidden", "auto"],
        optionTitles: ["Visible", "Hidden", "Scroll"],
    },
})
